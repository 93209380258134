.Flash {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 100;
  text-align: center;

  .flash-message {
    display: inline-block;
    padding: 10px 50px;
    border-radius: 8px;
    box-shadow: 0px 2px 30px rgba(black, 0.5);
    background-color: rgba(116, 101, 66, 0.5);
    backdrop-filter: blur(24px);
    color: white;
  }
}
