.Script {
  background: white;
  color: black;

  .script-header {
    background: rgba(black, 0.1);
    padding: 20px 10px;
  }

  .loading-indicator {
    height: 90vh;
    line-height: 90vh;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    font-size: 2em;
    text-transform: uppercase;
    color: rgb(128, 125, 120);
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.15em;
  }

  .script {
    .script-contents {
      font-family: "Courier";
      line-height: 1.2em;
      max-width: 600px;
      margin: 0 auto;
      padding: 100px 30px;

      .scene-heading {
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: bold;
        margin-top: 60px;
        position: relative;

        &:before {
          position: absolute;
          content: ">";
          width: 2px;
          left: -45px;
        }
      }

      p span {
        &.allcaps {
          text-transform: uppercase;
        }

        &.italic {
          font-style: italic;
        }

        &.bold {
          font-weight: bold;
        }
      }

      .character {
        padding: 0 60px;
        max-width: 180px;
        text-transform: uppercase;
        margin: 16px auto 0;
      }

      .parenthetical {
        padding: 0 45px;
        max-width: 280px;
        margin: 0 auto;
        opacity: 0.65;
        font-size: 0.85em;
      }

      .dialogue {
        padding: 0 30px;
        max-width: 360px;
        margin: 0 auto;
      }
    }
  }
}
