.Exhibit {
  overflow: auto;
  $corner-radius: 20px;
  color: white;
  background-color: rgb(19, 17, 14);
  border-radius: 20px;
  box-shadow: 0px 2px 10px 3px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1200px) {
    border-radius: 0;
  }

  &.locked .exhibit-header .bottom-info {
    min-height: 0;
  }

  &.dark {
    .exhibit-header {
      &:before {
        opacity: 0.5;
      }
      &:after {
        opacity: 0.8;
      }
    }
  }

  .exhibit-header {
    padding: 50px;
    margin: 0;
    line-height: 1.5em;
    border-radius: $corner-radius $corner-radius 0 0;
    background-size: cover;
    background-position: center bottom;
    position: relative;

    @media screen and (max-width: 1200px) {
      padding: 35px;
      border-radius: 0;
    }

    &:after {
      position: absolute;
      height: 100%;
      content: " ";
      width: 100%;
      max-width: 1000px;
      top: 0;
      left: 0;
      z-index: 0.5;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 60%,
        rgba(0, 0, 0, 0) 100%
      );
      opacity: 0.75;
      border-radius: $corner-radius 0 0 0;

      @media screen and (max-width: 1200px) {
        border-radius: 0;
      }

      @media screen and (max-width: 900px) {
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );
      }
    }

    .top-info {
      margin-bottom: 0px;
      z-index: 1;
      position: relative;

      .project-title {
        margin: 0 0 30px;
        font-size: 2.4em;
        font-family: "DM Serif Display", serif;
        font-weight: normal;
        text-shadow: 0 1px 2px black;
      }

      .format {
        font-family: "Roboto", sans-serif;
        text-shadow: 0 1px 2px black;
      }
    }

    .bottom-info {
      width: 100%;
      max-width: 600px;
      min-height: 350px;
      line-height: 1.5em;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .Rating {
        height: 0.9em;
        margin-right: 1em;
        display: inline;
        position: relative;
        top: 1px;
      }

      .genre {
        font-family: "Roboto", sans-serif;
        line-height: 1.2em;
        text-shadow: 0 1px 2px black;
        margin: 0;
      }

      .synopsis {
        font-size: 1.1em;
        line-height: 1.5em;
        font-family: "Crimson Pro", serif;
        text-shadow: 0 1px 2px black;
        margin: 12px 0 0;
      }

      .project-buttons {
        display: flex;
        margin: 24px 0 -24px -10px;

        .project-button {
          cursor: pointer;
          text-align: center;
          display: block;
          text-decoration: none;

          & + .project-button {
            margin-left: 20px;
          }

          .file-icon {
            background-color: rgba(white, 0.1);
            backdrop-filter: blur(24px);
            box-shadow: 0px 2px 30px rgba(black, 0.5);
            border-radius: 8px;
            padding: 16px;
            fill: rgba(white, 0.5);
            width: 34px;
            transition: all 0.25s;

            &.dark {
              background-color: rgba(black, 0.5);
            }
          }

          .project-button-label {
            color: rgba(white, 0.5);
            font-size: 0.9em;
            transition: all 0.25s;
            line-height: 18px;
            width: 80px;
          }

          &:hover {
            .file-icon {
              background-color: rgba(white, 0.8);
              fill: rgba(black, 0.7);
            }

            .project-button-label {
              color: rgba(white, 0.8);
            }
          }

          &:active {
            .project-button-icon {
              background-color: rgba(white, 0.95);
              fill: rgba(black, 0.85);
            }

            .project-button-label {
              color: rgba(white, 0.95);
            }
          }
        }
      }
    }

    .AuthPanel {
      position: relative;
      z-index: 1;
      margin: 24px auto 0;
    }
  }
}
