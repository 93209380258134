.Slider {
  position: relative;

  .items {
    list-style: none;
    padding: 10px 0 0;
    margin: -5px 0 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    z-index: 2;
    -ms-overflow-style: none; // IE 10+
    scrollbar-height: none; // Firefox
    scroll-snap-type: x mandatory;
    scroll-padding: 50px;

    @media screen and (max-width: 1200px) {
      scroll-padding: 35px;
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &.spacer {
      opacity: 0;
      position: relative;
      z-index: 1;
    }

    li {
      $item-width: 160px;
      flex-shrink: 0;
      width: $item-width;
      margin-right: 30px;
      scroll-snap-align: start;

      @media screen and (max-width: 1200px) {
        margin-right: 25px;
      }

      &:first-child {
        margin-left: 50px;

        @media screen and (max-width: 1200px) {
          margin-left: 35px;
        }
      }

      &:last-child {
        padding-right: 50px;

        @media screen and (max-width: 1200px) {
          padding-right: 35px;
        }
      }

      .item-img {
        display: block;
        width: $item-width;
        border-radius: 8px;
        box-shadow: 0 1px 10px black;
        margin-bottom: 20px;

        &.circle {
          border-radius: $item-width;
        }
      }

      .item-link {
        text-decoration: none;

        .item-img {
          transition: 0.2s transform;
        }

        &:hover .item-img {
          transform: scale(1.03);
        }

        &:active .item-img {
          transform: scale(1.01);
        }
      }

      &.large {
        width: 350px;
        .item-img {
          width: 350px;
        }
      }

      p {
        margin: 6px 0;
        text-decoration: none;

        &.detail {
          color: rgb(168, 157, 146);
          font-family: "Roboto", sans-serif;
          font-size: 0.82em;
        }

        &.title {
          font-size: 1.2em;
          font-family: "Crimson Pro", serif;
          color: rgb(235, 221, 206);
          font-weight: 700;
        }
      }
    }
  }
}
