.ExhibitGallery {
  position: fixed; /* Stay in place */
  z-index: 20;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(27, 23, 20);
  background-color: rgba(27, 23, 20, 0.9);
  backdrop-filter: blur(4px);

  .transition-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .transition-exit {
    opacity: 1;
  }
  .transition-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .scroll-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .scroll-indicator {
    z-index: 1000;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(blue, 0.7);
  }

  /* Position the content inside the overlay */
  .exhibit-content {
    position: relative;
    max-width: 1200px; /* 100% width */
    margin: 0 auto 50px;
  }
}
