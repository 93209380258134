.SongEnd {
  display: flex;
  $color: rgba(red, 0.4);

  .line {
    flex-grow: 1;
    border-top: 1px solid $color;
    position: relative;
    top: 0.5em;
  }

  .label {
    padding: 0 10px;
    color: $color;
    text-transform: uppercase;
  }
}
