.ExhibitSections {
  section {
    margin: 50px 0;

    @media screen and (max-width: 1200px) {
      margin: 35px 0;
    }

    & + section {
      margin-top: 80px;

      @media screen and (max-width: 1200px) {
        margin-top: 60px;
      }
    }

    .section-title {
      color: #866642;
      font-size: 2em;
      margin: 0 50px 30px;
      padding: 0;
      font-family: "DM Serif Display", serif;
      font-weight: normal;

      @media screen and (max-width: 1200px) {
        margin: 0 35px 20px;
      }
    }

    .container {
      margin: 0 50px;

      @media screen and (max-width: 1200px) {
        margin: 0 35px;
      }

      .fade-in-enter {
        opacity: 0;
      }
      .fade-in-enter-active {
        opacity: 1;
        transition: opacity 3000ms;
      }

      &.project-summary {
        column-width: 21em;
        column-gap: 2em;
        text-align: justify;

        p {
          margin: 0 0 12px;
          font-family: "Crimson Pro", serif;
          font-size: 1.1em;

          &:first-child:first-letter {
            color: #866642;
            float: left;
            font-family: "Six Caps", sans-serif;
            font-weight: normal;
            font-size: 3.8em;
            line-height: 0.8em;
            padding-right: 8px;
          }

          &:not(:first-child) {
            text-indent: 1.2em;
          }
        }
      }
    }
  }

  .original-music {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;

    .original-song {
      margin: 20px 60px 0 0;

      .music-icon {
        fill: rgba(white, 0.15);
        width: 46px;
        height: 46px;
        margin-right: 20px;
      }

      display: flex;
      flex-direction: row;

      .details {
        .song-title {
          margin: 0 0 6px;
          font-size: 1.2em;
          font-family: "Crimson Pro", serif;
          color: #ebddce;
          font-weight: 700;
        }

        .runtime {
          margin: 0;
          font-size: 0.82em;
          color: #a89d92;
          font-family: "Roboto", sans-serif;
        }
      }
    }
  }

  .Presentation {
    position: relative;
    padding-bottom: 59.17%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
