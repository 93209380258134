.SongPlayer {
  width: 100%;
  z-index: 10;
  padding: 16px 0;
  width: 100%;
  margin: -16px 0;

  &:focus {
    outline: none;
  }
}
