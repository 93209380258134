.ExhibitGalleryNav {
  outline: none;
  $hover-color: rgb(153, 131, 117);
  $button-color: rgb(74, 61, 53);
  $active-color: rgb(208, 185, 167);
  $size: 25px;
  $margin: 20px;
  position: relative;
  margin: $margin auto;
  max-width: 1200px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 360px) {
    justify-content: flex-start;
    padding-left: $margin;
  }

  .close-nav {
    position: absolute;
    right: 0px;
    top: 0px;

    @media screen and (max-width: 1200px) {
      right: $margin;
    }
  }

  .nav-container {
    background: rgba(black, 0.7);
    border-radius: 100px;
    padding: 4px;
  }

  .project-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    .state-indicators {
      line-height: $size;
      font-size: $size;
      margin: 0 10px;
      position: relative;
      top: -2px;

      .indicator {
        padding: 0 2px;
        margin: 0;
        cursor: pointer;
        line-height: $size;
        color: $button-color;

        &:hover {
          color: $hover-color;
        }

        &.active {
          color: $active-color;
        }
      }

      .category-start {
        margin-left: 15px;
      }
    }
  }

  .nav-button {
    margin: 0;
    cursor: pointer;
    position: relative;
    width: $size;
    height: $size;

    &:hover {
      &:before,
      &:after {
        background-color: $hover-color;
      }
    }

    &:active {
      &:before,
      &:after {
        background-color: $active-color;
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: " ";
      width: 2px;
      border-radius: 10px;
      background-color: $button-color;
    }

    &.close {
      &:before,
      &:after {
        $iconSize: $size * 0.64;
        top: ($size - $iconSize) * 0.5;
        left: ($size - $iconSize) * 1.34;
        height: $iconSize;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &.prev,
    &.next {
      $iconSize: $size * 0.36;

      &:before,
      &:after {
        left: ($size - $iconSize) * 0.73;
        height: $iconSize;
      }
    }

    &.prev {
      $iconSize: $size * 0.36;

      &:before {
        top: ($size - $iconSize) * 0.35;
        transform: rotate(45deg);
      }

      &:after {
        top: ($size - $iconSize) * 0.7;
        transform: rotate(-45deg);
      }
    }

    &.next {
      $iconSize: $size * 0.36;
      &:before {
        top: ($size - $iconSize) * 0.35;
        transform: rotate(-45deg);
      }

      &:after {
        top: ($size - $iconSize) * 0.7;
        transform: rotate(45deg);
      }
    }
  }
}
