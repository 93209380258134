.TeamMember {
  $width: 350px;
  $photo-width: 160px;
  width: $width;
  flex-grow: 1;
  display: flex;
  background: rgba(black, 0.2);
  border-radius: 20px;
  padding: 30px;
  margin: 15px;
  box-shadow: 0px 4px 20px rgba(black, 0.1);
  transition: 0.2s transform;
  text-decoration: none;

  &:hover {
    transform: scale(1.03);
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }

  .member-photo {
    width: $photo-width;
    height: $photo-width;
    border-radius: calc($photo-width / 2);
    margin-right: 30px;

    @media screen and (max-width: 500px) {
      margin-bottom: 30px;
      border-radius: 100px;
    }
  }

  .member-details {
    color: white;
    width: 100%;

    .member-firm {
      max-height: 30px;
      max-width: 200px;
      margin: 6px 0;
    }

    .member-detail {
      color: rgb(168, 157, 146);
      font-family: "Roboto", sans-serif;
      font-size: 13.12px;
      margin: 6px 0;
    }

    .address-line {
      display: block;
    }

    .member-name {
      a {
        text-decoration: none;
      }
      font-size: 20px;
      font-family: "Crimson Pro", serif;
      color: rgb(235, 221, 206);
      font-weight: 700;
    }
  }
}
