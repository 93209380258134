.Gallery {
  .tabs,
  .projects {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .tabs {
    display: flex;

    li {
      padding: 16px 24px;
      font-size: 0.85em;
      text-transform: uppercase;
      border-bottom: 2px solid rgba(white, 0.2);
      font-weight: 700;
      color: rgb(128, 125, 120);
      transition: 0.2s;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.15em;

      &:hover {
        color: rgb(235, 221, 206);
        border-bottom-color: rgb(191, 178, 164);
        cursor: pointer;
      }

      &.selected {
        cursor: default;
        color: rgb(189, 125, 54);
        border-bottom-color: rgb(189, 125, 54);
      }
    }
  }

  .projects {
    padding-top: 30px;
    position: absolute;
    left: 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    z-index: 2;
    -ms-overflow-style: none; // IE 10+
    scrollbar-height: none; // Firefox
    scroll-snap-type: x mandatory;
    scroll-padding: 10vw;

    @media screen and (min-width: 1200px) {
      scroll-padding: calc((100vw - 960px) / 2);
    }

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &.spacer {
      opacity: 0;
      position: relative;
      z-index: 1;
    }

    $item-width: 300px;
    $item-height: 400px;
    $item-margin: 30px;

    li {
      flex-shrink: 0;
      width: $item-width;
      margin-right: $item-margin;
      cursor: pointer;
      scroll-snap-align: start;

      &:first-child {
        margin-left: 10vw;
      }

      @media screen and (min-width: 1200px) {
        &:first-child {
          margin-left: calc((100vw - 960px) / 2);
        }
      }

      &:last-child {
        padding-right: $item-margin;
      }

      .project-img {
        display: block;
        width: $item-width;
        height: $item-height;
        box-shadow: 0 1px 10px black;
        background-color: grey;
        background-size: cover;
        filter: sepia(80%);
        border-radius: 8px;
        margin-bottom: 20px;
        transition: 0.2s;
        position: relative;
        cursor: pointer;
      }

      &:hover .project-img {
        filter: none;
        transform: scale(1.03);
      }

      &:active .project-img {
        transform: scale(1.01);
      }
    }

    p {
      margin: 6px 0;

      &.detail {
        color: rgb(168, 157, 146);
        font-family: "Roboto", sans-serif;
        font-size: 0.82em;
      }

      &.title {
        font-size: 1.2em;
        color: rgb(235, 221, 206);
        font-weight: 700;
      }
    }
  }
}
