.ExhibitHighlights {
  $corner-radius: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 30px rgba(black, 0.5);

  .highlight-background {
    background-size: cover;
    background-position: center bottom;
    position: absolute;
    height: 100%;
    width: 100%;
    transform: scaleY(-1);

    // Alternative blur solution:
    // filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1'%3E%3CfeGaussianBlur stdDeviation='24' result='b'/%3E%3CfeMorphology operator='dilate' radius='4'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#a");

    top: 0;
    left: 0;
  }

  .highlight-contents {
    background-color: rgba(white, 0.1);
    backdrop-filter: blur(24px);
    flex-wrap: wrap;
    padding: 15px 0;
    column-width: 240px;

    &.dark {
      background-color: rgba(black, 0.5);
    }

    .highlight {
      position: relative;
      z-index: 1;
      padding: 35px 50px;
      break-inside: avoid-column;

      @media screen and (max-width: 1200px) {
        padding: 20px 35px;
      }

      .label,
      .value {
        margin: 0;
        font-family: "Roboto", sans-serif;
      }

      .label {
        font-size: 0.8em;
        text-transform: uppercase;
        font-weight: bold;
        color: rgba(white, 0.5);
        margin-bottom: 10px;
      }
    }
  }

  &.page-bottom {
    .highlight-contents {
      border-radius: 0 0 $corner-radius $corner-radius;
    }

    .highlight-background {
      border-radius: $corner-radius $corner-radius 0 0;
    }
  }
}
