.AuthPanel {
  background-color: rgba(white, 0.1);
  backdrop-filter: blur(24px);

  border-radius: 12px;
  box-shadow: 0px 2px 30px rgba(black, 0.5);
  padding: 40px 40px 0;
  text-align: center;
  max-width: 310px;

  .auth-header {
    margin: 20px 0 0;
    font-family: "Crimson Pro", serif;
    font-weight: normal;
  }

  .lock-icon {
  }

  .auth-detail {
    font-family: "Roboto", sans-serif;
    color: rgba(white, 0.6);
    // font-size: 0.9em;
  }

  .auth-password {
    display: block;
    padding: 20px;
    width: calc(100% - 40px);
    background-color: rgba(black, 0.3);
    border: 1px solid rgba(white, 0.3);
    border-radius: 6px;
    text-align: center;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    outline: none;
    transition: all 0.3s;

    &::placeholder {
      text-transform: uppercase;
      letter-spacing: 0.3em;
      color: rgba(white, 0.4);
    }

    &:focus {
      box-shadow: 0 0 10px rgba(white, 0.2);
    }
  }

  &.dark {
    background-color: rgba(black, 0.4);

    .auth-password {
      background-color: rgba(black, 0.3);
      border: 1px solid rgba(white, 0.3);
    }
  }

  .auth-error,
  .auth-loading {
    font-family: "Roboto", sans-serif;
    font-size: 0.9em;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0;
    height: 40px;
  }
  .auth-error {
    color: rgba(255, 112, 112, 0.67);
  }
  .auth-loading {
    color: rgba(white, 0.2);
  }
}
