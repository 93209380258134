.Home {
  background-color: #1a1714;
  overflow: auto;

  &.modal-open {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  p,
  li,
  a {
    color: rgb(235, 221, 206);
    font-family: "Crimson Pro", serif;
  }

  h1,
  h2 {
    color: rgb(134, 102, 66);
    font-family: "DM Serif Display", serif;
    font-weight: normal;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  .header-enter {
    opacity: 0;
  }

  .header-enter-active {
    opacity: 1;
    transition: opacity 3s;
  }

  header {
    height: 100vh;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    background: radial-gradient(at 80% 140px, #383029 0%, rgba(#1a1714, 0) 60%);

    .headings {
      flex-shrink: 0;
      overflow: auto;
      padding: 0 20px;

      h1,
      h2 {
        color: #58493b;
        margin: 36px 0;
      }

      h1 {
        .bg-gradient {
          background: linear-gradient(
            to right,
            #40352f 0%,
            #705f55 20%,
            #0e0c0a 50%,
            #261e1a 100%
          );
          background-clip: text;
          color: transparent;
          display: inline-block;
        }
        font-size: 3em;
      }

      h2 {
        font-size: 1.5em;
      }

      .separator {
        width: 240px;
        border-top: 1px solid rgba(19, 16, 13, 0.7);
        border-bottom: 1px solid rgba(162, 138, 125, 0.3);
        margin: 0 auto;
      }
    }

    .hero-container {
      flex-shrink: 1;
      flex-basis: 600px;

      .hero {
        height: 100%;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        background-image: url("./images/ashly-hero.png");
        background-position: top center;
        background-size: cover;
      }
    }
  }

  section {
    max-width: 960px;
    margin: 100px auto 200px;
    padding: 0 120px;

    @media screen and (max-width: 1200px) {
      padding: 0 10vw;
    }

    &.purpose p {
      font-size: 1.2em;
      line-height: 1.3;

      a {
        text-decoration: none;
        color: rgb(203, 169, 130);
        transition: color 0.1s, border-color 0.1s;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid rgba(173, 122, 65, 0.5);
          color: rgb(173, 122, 65);
        }

        &:active {
          color: rgb(177, 131, 78);
        }
      }
    }

    &.quote {
      p {
        position: relative;
        margin: 0 25px;

        .quote-text {
          font-style: italic;
          font-size: 1.8em;
        }

        .author {
          margin: 20px 0 0 30px;
          display: block;
          text-transform: uppercase;
          font-weight: 700;
          color: rgb(189, 125, 54);
          font-family: "Roboto", sans-serif;
          letter-spacing: 0.15em;

          &:before {
            content: "——";
            letter-spacing: -0.2em;
            margin-right: 0.5em;
          }
        }

        .open-quote {
          position: absolute;
          font-size: 140px;
          opacity: 0.1;
          top: -50px;
          left: -60px;
        }
      }
    }

    &.contact {
      p {
        font-size: 1.2em;
        line-height: 1.2em;
        color: #a89786;
      }

      .contact-logo {
        width: 450px;
        max-width: 100%;
      }

      .contact-info {
        .contact-name {
          color: #ebddce;
          font-size: 0.85em;
          text-transform: uppercase;
          font-weight: 700;
          font-family: "Roboto", sans-serif;
          letter-spacing: 0.15em;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        sup {
          vertical-align: baseline;
          position: relative;
          top: -0.4em;
        }
      }

      .contact-link {
        color: #a89786;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: #ebddce;
        }
      }
    }

    .side-photo {
      float: right;
      max-width: 300px;
      margin: 0 0 40px 40px;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      filter: sepia(30%) brightness(90%) contrast(120%);
      transition: filter 0.2s ease-in-out;

      &:hover {
        filter: none;
      }
    }
  }

  .team-members {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
  }

  footer {
    max-width: 1024px;
    margin: 60px auto 120px;

    .social {
      .icon {
        width: 40px;

        path {
          fill: rgb(92, 66, 35);
          transition: fill 0.2s ease-in-out;
        }

        &:hover {
          path {
            fill: rgb(134, 102, 66);
          }
        }
      }
    }

    p {
      text-align: center;
      color: #504840;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin: 0 5px;

        a {
          text-decoration: none;
          color: inherit;
          transition: 0.2s;

          &:hover {
            text-decoration: underline;
            color: #544b41;
          }
        }
      }
    }
  }
}

/* Media query for narrow browsers */
@media (max-width: 800px) {
  .Home section .side-photo {
    float: none;
    max-width: 100%;
    margin: 0 0 20px;
  }
}
